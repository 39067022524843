<template>
    <div class="page">
        <a-form class="ctrl" layout="inline">
            <a-form-item label="专业方向">
                <a-select show-search placeholder="专业" v-model="search.spe_name" style="min-width: 200px">
                    <a-select-option v-for="(item, index) in MajorTypeList" :key="index" :value="item.name" >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" @click="searchData">搜索</a-button>
            </a-form-item>
        </a-form>
        <a-table class="sec" size="small" :rowKey="(record) => record.id" bordered :columns="columns" :data-source="list" :pagination="false">
            <template slot="place" slot-scope="text, record">
                {{record.fromPortDesc}} -- {{record.toPortDesc}}
            </template>
        </a-table>

        <a-pagination class="pagination" showQuickJumper v-model="pages.now_page" :defaultPageSize="50" :total="pages.amount" @change="getRechargeList" />

        <!-- <a-pagination class="margin15" showQuickJumper v-model="options.now_page" :defaultPageSize="50" :total="amount"
      @change="doSearch" /> -->
    </div>
</template>

<style lang='scss' scoped>
</style>

<script>
const columns = [
    {
        title: "姓名",
        dataIndex: "name",
    },
    {
        title: "联系方式",
        dataIndex: "tel",
    },
    {
        title: "执业机构",
        dataIndex: "office",
    },
    {
        title: "年龄",
        dataIndex: "birthday",
    },
    {
        title: "执业年份",
        dataIndex: "year",
    },
    {
        title: "专业方向",
        dataIndex: "spe_name",
    },
];
export default {
    data() {
        return {
            columns,
            list: [],
            pages: {
                now_page: 1,
                all_page: 1,
                amount: 0,
            },
            MajorTypeList:[],
            search: {
                spe_name: "",
            },
            recharge_money: 0,
        };
    },
    created() {
       // if (this.$route.query.who) this.search.who = this.$route.query.who;
        this.getRechargeList();
        this.getMajorTypeList()
    },
    methods: {
        clear() {
            this.search = {
                who: null,
                tel: "",
                name: "",
                start_time: null,
                end_time: null,
            };

            this.getRechargeList();
            
        },
        searchData() {
            this.pages.now_page = 1;
            this.getRechargeList();
        },
        async getRechargeList() {
            const that = this;
            const r = await this.$axios.post("/customer/getLawyerList", {
                page: that.pages.now_page,
                search: that.search,
            });

            this.list = r.list;
            this.pages.amount = r.amount;
        },
        async getMajorTypeList() {
            const r = await this.$axios.post("/customer/getMajorType");
            this.MajorTypeList = r.list;
        },
    },
};
</script>