<template>
  <div class="flex-page">
    <a-form class="ctrl" layout="inline">
      <!-- <a-form-item label="用户组">
        <a-select style="width: 100px" v-model="options.group_id">
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option v-for="(group, index) in groupList" :key="index" :value="group.id">{{group.group_name}}</a-select-option>
        </a-select>
      </a-form-item> -->

      <a-form-item label="姓名">
        <a-input
          style="width: 200px"
          placeholder="请输入姓名"
          v-model="options.name"
        ></a-input>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="search">搜索</a-button>
      </a-form-item>
    </a-form>

    <div class="statistics">共{{ page.amount }}个</div>

    <a-table
      class="main"
      size="small"
      :rowKey="(record) => record.id"
      bordered
      :columns="columns"
      :data-source="list"
      :pagination="false"
    >
      <template slot="zhuangtai" slot-scope="text, record">
        <a-tag v-if="record.status == 1" color="green">正常</a-tag>
        <a-tag v-if="record.status == 0" color="red">已删除</a-tag>
      </template>
      <template slot="action" slot-scope="text, record">
        <span>
          <router-link :to="'/user/update?id=' + record.id">编辑</router-link>
        </span>
        <a-divider type="vertical" />

        <a-popconfirm
          v-if="record.status == 1"
          title="确定删除吗？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="doStatus(record.id, 0)"
        >
          <a href="javascript:;">删除</a>
        </a-popconfirm>

        <a-popconfirm
          v-if="record.status == 0"
          title="确定恢复吗？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="doStatus(record.id, 1)"
        >
          <a>恢复</a>
        </a-popconfirm>
        <a-divider type="vertical" />
      </template>
    </a-table>

    <a-pagination
      class="pagination"
      showQuickJumper
      v-model="page.now_page"
      :defaultPageSize="page.page_size"
      :total="page.amount"
      @change="changePage"
    />
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "id",
  },
  {
    title: "姓名",
    dataIndex: "name",
  },
  {
    title: "用户名",
    dataIndex: "username",
  },
  {
    title: "备注",
    dataIndex: "desc",
  },
  {
    title: "添加时间",
    dataIndex: "created_at",
  },
  {
    title: "状态",
    scopedSlots: { customRender: "zhuangtai" },
  },
  {
    title: "操作",
    scopedSlots: {
      customRender: "action",
    },
  },
];

export default {
  data() {
    return {
      columns,
      list: [],
      options: {
        name: "",
      },
      page: {
        now_page: 1,
        page_size: 50,
        amount: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // async getGroupList() {
    //   const r = await this.$axios.get("/user/getGroupList");

    //   if (r.status == 1) {
    //     this.groupList = r.list;
    //   }
    // },

    search() {
      this.page.now_page = 1;
      this.getList();
    },
    changePage(e) {
      this.page.now_page = e;
      this.getList();
    },

    async getList() {
      const options = { ...this.options };
      options.page = this.page.now_page;
      const r = await this.$axios.get("/user/getList", {
        params: options,
      });
      console.log(r);

      this.list = r.list;
      this.page.amount = r.amount;
    },
    async doStatus(id, status) {
      const r = await this.$axios.post("/user/updateStatus", {
        id: id,
        status: status,
      });
      if (r.status == 1) {
        this.getList();
      }
    }
  },
};
</script>