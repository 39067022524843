<template>
    <div class="page">
        <a-form class="ctrl" layout="inline">
            <a-form-item label="类型">
               <a-select placeholder="选择分类" optionFilterProp="children" v-model="search.type" style="width: 200px">
                <a-select-option value="1">知识讲堂</a-select-option>
                <a-select-option value="2">热门问答</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item>
                <a-button type="primary" @click="doSearch">搜索</a-button>
            </a-form-item> 
        </a-form>

        <a-table class="sec" size="small" bordered :dataSource="list" :columns="columns" :pagination="false" :rowKey="(record) => record.id">
            <!-- <template slot="thumb" slot-scope="text, record">
                        <img v-if="record.thumb_show" :src="record.thumb_show" style="height: 35px" alt="" />
                    </template> -->
            <template slot="titles" slot-scope="text, record">
                {{record.title}}
            </template>

            <template slot="type" slot-scope="text, record">
                <span>{{record.type==1?'知识讲堂':'热门问答'}}</span>
            </template>

            <template slot="caozuo" slot-scope="text, record">
                <router-link :to="'/article/view?id=' + record.id">
                    <a-button size="small" type="primary"> 查看 </a-button>
                </router-link>

                <router-link :to="'/article/edit?id=' + record.id + '&type=' + record.major_id">
                    <a-button style="margin-left: 5px" size="small" type="primary">
                        编辑
                    </a-button>
                </router-link>

                <a-popconfirm title="确定要删除吗" @confirm="del(record.id)" okText="确定" cancelText="取消">
                    <a-button style="margin-left: 5px" type="danger" size="small">
                        删除
                    </a-button>
                </a-popconfirm>
            </template>
        </a-table>
        <!-- <a-pagination @change="onChange" :total="amount" :defaultPageSize="50" :defaultCurrent="search.now_page" showQuickJumper class="margin15" /> -->
        <a-pagination class="margin15" showQuickJumper v-model="pages.now_page" :defaultPageSize="20" :total="pages.amount" @change="onChange" />
    </div>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
    data() {
        return {
            columns: [
                // {
                //     title: "#",
                //     dataIndex: "id",
                // },
                {
                    title: "标题",
                    dataIndex: "title",
                    scopedSlots: {
                        customRender: "titles",
                    },
                },
                {
                    title: "类型",
                    dataIndex: "type",
                    scopedSlots: {
                        customRender: "type",
                    },
                },
                {
                    title: "专业标签",
                    dataIndex: "major_name",
                },
                {
                    title: "发布时间",
                    dataIndex: "created_at",
                },
                {
                    title: "操作",
                    dataIndex: "caozuo",
                    scopedSlots: {
                        customRender: "caozuo",
                    },
                },
            ],
            search: {
                type: '',
            },
            pages: {
                now_page: 1,
                all_page: 1,
                amount: 0,
            },
            cateList: [],
            list: [],
        };
    },
    // watch: {
    //     "$route.query.type"() {
    //         console.log(this.$route.query.type);
    //     },
    // },
    async created() {
        // this.search.type = this.$route.query.type;
        // // 获取所有的资料分类
        // const r = await this.$axios.get(
        //     "/article/getCateList?type=" + this.search.type
        // );
        // if (r.status == 1) {
        //     this.cateList = r.list;
        // }
    },
    mounted() {
        this.getList();
    },
    methods: {
        doSearch() {
            this.getList();
        },
        async getList() {
            const r = await this.$axios.post("/article/getArticleList", {
                page: this.pages.now_page,
                search:this.search
            });
            this.list = r.list;
            this.pages.amount = r.amount;
        },
        onChange(e) {
          //  this.search.now_page = e;
            this.getList();
        },
        async del(id) {
            const r = await this.$axios.post("/article/delArticle", {
                id: id,
                status: -1,
            });

            if (r.status == 1) {
                this.getList();
            }
        },
        a(e) {
          //  this.search.cate_id = e.id;
            this.getList();
        },
    },
};
</script>

<style>
</style>