<template>
    <div class="component-wrap">
        <a-form class="form sec">
            <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="姓名">
                <a-input style="width: 300px" v-model="form.name" placeholder="请输入律师姓名" />
            </a-form-item>
            <!-- <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="头衔">
                <a-input v-model="form.title" placeholder="请输入头衔，如主任、合伙人、律师" style="width: 300px"></a-input>
            </a-form-item>  --> 

            <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="执业号">
                <a-input style="width: 300px" v-model="form.no" placeholder="请输入职业号" />
            </a-form-item>

            <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="职业机构">
                <a-input style="width: 300px" v-model="form.office" placeholder="请输入职业机构" />
            </a-form-item>

            <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="性别">
                <a-radio-group v-model="form.sex">
                    <a-radio value="1">男</a-radio>
                    <a-radio value="0">女</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="工作电话">
                <a-input v-model="form.tel" placeholder="请输入工作电话" style="width: 300px"></a-input>
            </a-form-item>

            <!-- 我也不知道为啥不加这个变量，上传的图片就不显示，就很神奇 -->
            <span style=" display: none;">{{headimgurl}}</span>

            <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="照片">
                <a-upload name="avatar" listType="picture-card" class="avatar-uploader" :fileList="path" :remove="delPicture" :beforeUpload="beforeUploadPicture" @change="uploadPicture" :showUploadList="false">
                    <img style="width: 100px; height: 100px" v-if="form.headimgurl" :src="API + form.headimgurl" alt="avatar" />
                    <div v-else>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">选择照片</div>
                    </div>
                </a-upload>

            </a-form-item>

            <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="擅长专业">
                <a-select style="width: 300px" mode="tags" v-model="form.specialty">
                    <a-select-option v-for="(item, key) in specialtys" :key="key" :value="item">
                        {{ item }}
                    </a-select-option>
                </a-select>
                <div style="font-size: 12px; color: #cccccc">
                    可多选或者直接输入，输入后按tab键
                </div>
            </a-form-item>
        </a-form>
        <!-- 提交按钮 -->
        <div class="buttons">
            <a-button type="primary" @click="sub">提交保存</a-button>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.form {
    padding: 40px;
}
.buttons {
    text-align: center;
    padding: 20px 0;
    background: #f5f5f5;
}
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
    display: table-cell;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
}
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>
<script>
export default {
    data() {
        return {
            formItemLayout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 8 },
            },
            
           specialtys: [
                "刑事",
                "婚姻家庭",
                "公司法",
                "金融证券保险",
                "建筑房地产",
                "知识产权",
                "劳动法",
                "涉外法律服务",
                "行政法",
            ],
            path: [],
            form: {},

            path_wxCode: [],
            path_mpCode: [],
            headimgurl:''
        };
    },
    async asyncData({ $axios, params }) {
        const r = await $axios.$get("/lawyer/getMyData");
        let form = r;
        if (r.specialty) {
            form.specialty = r.specialty.split("、");
        }
        const lists = await $axios.$post("/lawyer/getAddPageData");
        return {
            groupList: lists.groupList,
            teacherList: lists.teacherList,
            depList: lists.depList,
            form: form,
        };
    },
    methods: {
        upFile(e) {
            this.form.signname = e || "";
        },
        async sub() {
            const that = this;

            console.log(that.form)
            const r = await this.$axios.post(
                "/customer/addLawyer",
                that.form
            );
            this.$message[r.s](r.msg);

        },

        async uploadPicture() {
            const that = this;
            const formData = new FormData();
            formData.append("file", that.path[0]);
            console.log(JSON.stringify( that.path[0]))
            const r = await this.$axios({
                method: "post",
                url: "/file/uploadSingleFile",
                cache: false,
                data: formData,
                processData: false,
                contentType: false,
            });

            if (r.status == 1) {
                this.form.headimgurl = r.file.file_path;
                this.headimgurl = this.API + r.file.file_path
                console.log(this.headimgurl)
            } else {
                this.$message[r.s](r.msg);
            }
        },
        delPicture() {
            this.path = [];
        },
        beforeUploadPicture(file) {
            if (!file.name) {
                return this.$message.error("没有文件名");
            }
            // 检测文件名有没有扩展名
            const arr = file.name.split(".");
            if (arr.length == 1) {
                return this.$message.error("文件没有扩展名");
            }
            const extensionName = arr[arr.length - 1];

            this.path = [];
            this.path = [...this.path, file];
            return false;
        },
    },
};
</script>

<style>
</style>