
<template>
  <div class="page">
    <a-form class="form sec" :label-col="{ span: 5 }" :wrapper-col="{ span: 10 }">
      <a-form-item label="车辆类型：">
        <a-radio-group v-model="form.type">
          <a-radio value="新车">新车</a-radio>
          <a-radio value="二手车">二手车</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="车架号">
        <a-input style="width: 60%" v-model="form.chejia" placeholder="请输入车架号"></a-input>
      </a-form-item>
      <a-form-item label="发动机号">
        <a-input style="width: 60%" v-model="form.fadongji" placeholder="请输入发动机号"></a-input>
      </a-form-item>
      <a-form-item label="车牌号">
        <a-input style="width: 60%" v-model="form.chepai" placeholder="请输入车牌号"></a-input>
      </a-form-item>
      <a-form-item label="购车价格">
        <a-input style="width: 60%" v-model="form.buy_money" placeholder="请输入购车价格"></a-input>
      </a-form-item>
      <a-form-item label="购车日期">
        <a-date-picker placeholder='购车日期' v-model="form.buy_date" format="YYYY年MM月DD日" />
      </a-form-item>
      <a-form-item label="附件">
        <UploadFile  @callback="uploaded" :mult="true" folder="article" :oldFiles="form.files" />
      </a-form-item>
      <a-form-item label="服务费用">
        <a-input style="width: 60%" v-model="form.fee" placeholder="请输入服务费用"></a-input>
      </a-form-item>
      <a-form-item label="服务期限">
        <a-date-picker placeholder='开始期限' v-model='form.start_at' format="YYYY年MM月DD日" @change='dateFn' /> ~
        <a-date-picker placeholder='结束期限' v-model='form.end_at' format="YYYY年MM月DD日" />
      </a-form-item>
      <a-form-item label="客户姓名">
        <a-input style="width: 60%" v-model="form.name" placeholder="请输入客户姓名"></a-input>
      </a-form-item>
      <a-form-item label="客户身份证号">
        <a-input style="width: 60%" v-model="form.idcard" placeholder="请输入客户身份证号"></a-input>
      </a-form-item>
      <a-form-item label="客户手机号">
        <a-input style="width: 60%" v-model="form.mobile" placeholder="请输入客户手机号"></a-input>
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea style="width: 60%" v-model="form.remarks" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 6 }" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 10, offset: 5 }">
        <a-button type="primary" @click="sub">提交</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<style lang='scss' scoped>
  hr {
    border-top: none;
    margin: 40px;
  }

  h4 {
    margin-left: 40px;
  }
</style>

<script>
  import moment from "moment";
  import UploadFile from "../../components/uploadFile";
  export default {
    components: { UploadFile },
    data() {
      return {
        
        form: {
          type:'',
          brand:'',
          year:'',
          style:'',
          chejia:'',
          fadongji:'',
          chepai:'',
          buy_money:'',
          buy_date:'',
          buy_fapiao:'',
          start_at:'',
          end_at:'',
          name:'',
          idcard:'',
          mobile:'',
          fee:'',
        //  remarks:''
        },
      }
    },
    beforeCreate() { },
    created() { },
    mounted() { },
    methods: {
      async sub() {
        const r = await this.$axios.post("/setting/create/doAddCreate", this.form);
          if(r.status == 1){
            
          }
        console.log(this.form)

      },
      dateFn() {
        console.log('111111111111111111')
        this.form.end_at = moment(this.form.start_at).add("year", 1).format("YYYY-MM-DD")
        this.form.start_at = moment(this.form.start_at).format("YYYY-MM-DD")
        console.log(this.form.end_at)
      },
      uploaded(files) {
        this.form.buy_fapiao=files
    },
    }
  }
</script>