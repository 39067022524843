import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import store from '../store/index'

const routes = [
  // =================== 首页 ===================
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页'
    },
    component: require('@/pages/index').default
  },

  // =================== 登录 ===================
  {
    path: '/user/login',
    name: 'login',
    meta: {
      layout: 'simple'
    },
    component: require('@/pages/user/login').default
  },
  {
    path: '/user/updatePsw',
    name: 'updatePsw',
    meta: {
      title: '修改密码'
    },
    component: require('@/pages/user/updatePsw').default
  },

  // =================== 单证管理 ===================
  {
    path: '/order/create',
    name: 'orderCreate',
    meta: {
      title: '单证录入'
    },
    component: require('@/pages/order/create').default
  },
  {
    path: '/order/myList',
    name: 'orderMyList',
    meta: {
      title: '单证列表'
    },
    component: require('@/pages/order/myList').default
  },
  {
    path: '/order/detail',
    name: 'orderDetail',
    meta: {
      title: '订单详情'
    },
    component: require('@/pages/order/detail').default
  },



  // =================== 充值记录 ===================
  {
    path: '/recharge/list',
    name: 'rechargeList',
    meta: {
      title: '充值记录'
    },
    component: require('@/pages/recharge/list').default
  },
  {
    path: '/recharge/detail',
    name: 'rechargeDetail',
    meta: {
      title: '充值详情'
    },
    component: require('@/pages/recharge/detail').default
  },

  // =================== 客户管理 ===================
  {
    path: '/customer/create',
    name: 'customerCreate',
    meta: {
      title: '添加客户'
    },
    component: require('@/pages/customer/create').default
  },
  {
    path: '/customer/list',
    name: 'customerList',
    meta: {
      title: '客户列表'
    },
    component: require('@/pages/customer/list').default
  },
  {
    path: '/customer/inquiryList',
    name: 'customerInquiryList',
    meta: {
      title: '咨询列表'
    },
    component: require('@/pages/customer/inquiryList').default
  },
  {
    path: '/customer/info',
    name: 'customerInfo',
    meta: {
      title: '客户资料'
    },
    component: require('@/pages/customer/info').default
  },
  {
    path: '/customer/recharge',
    name: 'customerRecharge',
    meta: {
      title: '客户充值'
    },
    component: require('@/pages/customer/recharge').default
  },
  {
    path: '/customer/update',
    name: 'customerUpdate',
    meta: {
      title: '编辑客户'
    },
    component: require('@/pages/customer/update').default
  },
  {
    path: '/lawyer/list',
    name: 'lawyerList',
    meta: {
      title: '律师列表'
    },
    component: require('@/pages/lawyer/list').default
  },

  {
    path: '/lawyer/addLawyer',
    name: 'addLawyer',
    meta: {
      title: '添加律师'
    },
    component: require('@/pages/lawyer/addLawyer').default
  },
  
// =================== 文章管理 ===================

  
  {
    path: '/article/list',
    name: 'articleList',
    meta: {
      title: '文章列表'
    },
    component: require('@/pages//article/list').default
  },
  {
    path: '/article/create',
    name: 'addArticle',
    meta: {
      title: '添加文章'
    },
    component: require('@/pages/article/create').default
  },
  {
    path: '/article/view',
    name: 'viewArticle',
    meta: {
      title: '文章详情'
    },
    component: require('@/pages/article/view').default
  },
  {
    path: '/article/edit',
    name: 'viewEdit',
    meta: {
      title: '文章编辑'
    },
    component: require('@/pages/article/edit').default
  },
  //=================== 合同 =============
 
  {
    path: '/contract/list',
    name: 'contract',
    meta: {
      title: '合同审查' 
    },
    component: require('@/pages/contract/list').default
  },
  
  // =================== 管理员 ===================
  {
    path: '/user/create',
    name: 'userCreate',
    meta: {
      title: '添加管理员'
    },
    component: require('@/pages/user/create').default
  },
  {
    path: '/user/list',
    name: 'userList',
    meta: {
      title: '管理员列表'
    },
    component: require('@/pages/user/list').default
  },
  {
    path: '/user/update',
    name: 'userUpdate',
    meta: {
      title: '修改管理员'
    },
    component: require('@/pages/user/update').default
  },

  // =================== 系统设置 ===================
  {
    path: '/settings/car',
    name: 'settingsCar',
    meta: {
      title: '车型管理'
    },
    component: require('@/pages/settings/car/list').default
  },
]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  const navs = store.state.navs;
  let selectedKeys = []; //当前选中菜单
  let openKeys = []; //当前展开菜单
  const breadcrumbs = []; //面包屑

  const now_path = to.path;
  let nowNav; //当前正在运算的nav

  for (let nav of navs) {
    if (nav.path == now_path || nav.route == now_path) {
      nowNav = nav;
      break
    }
  }

  if (!nowNav) return next()

  const getFather = (id) => {
    for (let nav of navs) {
      if (nav.id == id) return nav
    }
  }

  // 根据father层层查找
  const hello = (nowNav) => {
    breadcrumbs.unshift(nowNav.title);
    if (nowNav.class == 2 && nowNav.father) selectedKeys.unshift(nowNav.id);
    if (nowNav.class == 1 && !nowNav.father) openKeys.unshift(nowNav.id);

    if (nowNav.father !== 0) {
      const father = getFather(nowNav.father);
      hello(father)
    }
  }

  if (nowNav.class == 1) {
    selectedKeys.unshift(nowNav.id);
    breadcrumbs.unshift(nowNav.title);
  }
  else {
    hello(nowNav)
  }

  store.commit('setActiveMenu', {
    selectedKeys,
    openKeys,
    breadcrumbs
  })

  next()
})

// 避免跳转到当前网址的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router
