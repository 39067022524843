<template>
    <div class="login-wrap">
        <div class="login-container">
            <div class="left">
                <div>一律有法<br />管理后台</div>
            </div>
            <div class="login-form">
                <img class="logo" src="/logo.png" alt="" />
                <a-form-item>
                    <a-input @pressEnter="login" size="large" type="text" v-model="form.username" placeholder="请输入用户名">
                        <a-icon slot="prefix" type="user" style="color: rgba(0, 0, 0, 0.25)" />
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-input @pressEnter="login" size="large" type="password" v-model="form.password" placeholder="请输入密码">
                        <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-button size="large" type="default" @click="login" html-type="button" class="login-form-button">登录系统
                    </a-button>
                </a-form-item>

                <div class="copyright">
                    <span>&copy; 一律有法管理平台</span>
                    <!-- <a style="margin-left: 20px" href="http://www.miibeian.gov.cn/">鲁ICP备2021031447号-2</a> -->
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.login-wrap {
    width: 100vw;
    height: 100vh;
    background-image: url("/bj.png");
    background-size: cover;
    color: #fff;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .left {
        width: 320px;
        height: 360px;
        background-image: url("/xbj.png");
        background-size: cover;
        div {
            font-size: 30px;
            font-weight: 500;
            margin: 120px 0 0 60px;
        }
    }

    .login-form {
        padding: 40px;
        background: #fff;
        width: 500px;
        height: 500px;
        box-shadow: -13px 13px 19px 1px rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        position: relative;

        .logo {
            width: 120px;
            height: 120px;
            display: block;
            margin: 20px auto 40px auto;
        }

        .login-form-button {
            width: 100%;
            height: 50px;
            background: #2a6afa;
            border-radius: 10px;
            color: #fff;
            border: none;
            margin-top: 20px;
        }

        .copyright {
            position: absolute;
            bottom: 20px;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 12px;
            color: #999;
        }
    }
}
</style>

<script>
export default {
    data() {
        return {
            form: {
                username: "",
                password: "",
            },
        };
    },
    created() {
        // 清理高亮 aside created后会重新生成，这里只需要清理
        localStorage.removeItem("activeNav");
    },
    methods: {
        async login() {
            if (!this.form.username) {
                return this.$message.error("请输入用户名");
            }
            if (!this.form.password) {
                return this.$message.error("请输入密码");
            }

            const r = await this.$axios.post("/user/doLogin", this.form);

            if (r.status == 1) {
                this.getNavs();
                this.$store.commit("saveData", r.data);
                setTimeout(() => {
                    this.$router.push("/");
                }, 500);
            }
        },

        async getNavs() {
            const r = await this.$axios.get("/user/getNavs");
            if (r.status != 1) return;

            // 保存所有导航数组到store
            const navs = r.navs;
            this.$store.commit("setNavs", navs);

            // ----------------- 生成一级菜单 -----------------
            const menus = []; //最终生成的一二级菜单
            navs.forEach((nav) => {
                if (nav.class == 1) menus.push(nav);
            });

            // ----------------- 生成二级菜单 -----------------
            for (let menu of menus) {
                menu.children = [];
                navs.forEach((nav) => {
                    if (nav.father == menu.id && nav.show == 1)
                        menu.children.push(nav);
                });
                if (menu.children.length == 0) delete menu.children;
            }

            // 保存菜单到store
            this.$store.commit("setMenus", menus);
        },
    },
};
</script>