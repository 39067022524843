<template>
  <a-modal :title="name" okText="确定" width='300px' cancelText="取消" @cancel='editlFn' v-model="visible" @ok="doEdit">
        <a-form>
          <div v-if="key =='style'">
            <a-form-item label="配置" style="display: flex">
                <a-input type="text" v-model="form.val"></a-input>
            </a-form-item>
            <a-form-item label="价格" style="display: flex">
                <a-input type="text" v-model="form.val1"></a-input>
            </a-form-item>
          </div>
            
            <a-form-item v-else label="名称" style="display: flex">
                <a-input type="text" v-model="form.val"></a-input>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
  props: ["d"],
  data() {
    return {
      visible: false,
      form: {},
      key:'',
      name:''
    }
  },
  beforeCreate () {},
  created () {;
  console.log(this.d)
      this.visible = this.d.show;
      this.name = this.d.title;
      this.form = { ...this.d };
      this.key = this.d.key
  },
  methods: {
    async doEdit() {
        const r = await this.$axios.post("/setting/car/doEdit", this.form);
        if(r.status == 1){
          this.$emit('visibleEdit',false)
          this.$emit('getCarEditList')
        }
    },
    editlFn(){
      this.$emit('visibleEdit',false)
    }
  }
}
</script>