<template>
    <div class="page">
        <a-form class="ctrl" layout="inline">
            <a-form-item label="咨询类型">
                <a-select show-search placeholder="咨询类型" v-model="search.spe_name" style="min-width: 200px">
                    <a-select-option v-for="(item, index) in MajorTypeList" :key="index" :value="item.name">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="咨询人">
                <a-input style="width: 200px" placeholder="请输入姓名" v-model="search.user_name"></a-input>
            </a-form-item>
            <a-form-item label="服务律师">
                <a-select show-search placeholder="选择律师" v-model="search.lawyer_id" style="min-width: 200px">
                    <a-select-option v-for="(item, index) in add.lawyerList" :key="index" :value="item.id">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item label="咨询时间">
                <a-date-picker v-model="search.start_at" /> -
                <a-date-picker v-model="search.end_at" />
            </a-form-item>

            <a-form-item>
                <a-button type="primary" @click="searchData">搜索</a-button>
                <a-button type="default" @click="clear" style="margin: 0 10px">清除</a-button>
            </a-form-item>
        </a-form>

        <a-form class="ctrl" layout="inline">
            <a-form-item>
                <a-button type="primary" @click="addFn"> 添加 </a-button>
            </a-form-item>
        </a-form>

        <a-table class="sec" size="small" :rowKey="(record) => record.id" bordered :columns="columns" :data-source="list" :pagination="false" :scroll="{ x: 200 }">

            <template slot="content_a" slot-scope="text, record">
                {{record.content}}
            </template>
            <template slot="caozuo" slot-scope="text, record">
                <a-button @click="replyFn(record.id,record.type,record.content,record.inquiry_at)" size="small">指派律师</a-button>
                <a-button @click="typeFn(record.id)" size="small">分配类型</a-button>
                <a-button @click="remarksFn(record.id)" size="small">客服备注</a-button>
                <a-popconfirm  title="确定要发布吗" @confirm="release(record.id,record.type,record.content,record.inquiry_at)" okText="确定" cancelText="取消">
                    <a-button  size="small">发布咨询</a-button>
                </a-popconfirm>
            </template>
            <!-- <template slot="caozuo" slot-scope="text, record">v-if="!record.release"
                <a-button @click="remarksFn(record.id)" size="small">备注</a-button>
            </template> -->
        </a-table>

        <a-pagination class="pagination" showQuickJumper v-model="pages.now_page" :defaultPageSize="50" :total="pages.amount" @change="getRechargeList" />

        <a-modal title="指派律师" v-model="editInquiry.show" @ok="doEditInquiry" okText="确认" cancelText="取消">
            <a-form class="sec" style="padding:0 20px" :label-col="{ span: 5 }" :wrapper-col="{ span: 10 }">
                <a-form-item label="选择律师" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                    <a-select show-search placeholder="选择律师" v-model="editInquiry.lawyer_id" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                        <a-select-option v-for="(item, index) in add.lawyerList" :key="index" :value="item.id" @click="lawyerFn(item.name)">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="备注" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                    <a-textarea style="min-height:200px" v-model="editInquiry.service_bz" placeholder="请输入回复内容" autoSize />
                </a-form-item>
            </a-form>
        </a-modal>

        <a-modal title="备注" v-model="editRemarks.show" @ok="doEditRemarks" okText="确认" cancelText="取消">
            <a-form class="sec" style="padding:0 20px" :label-col="{ span: 5 }" :wrapper-col="{ span: 10 }">
                <a-form-item label="备注" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                    <a-textarea style="min-height:200px" v-model="editRemarks.remarks" placeholder="请输入回复内容" autoSize />
                </a-form-item>
            </a-form>
        </a-modal>

        <a-modal title="分配类型" v-model="typeObj.show" @ok="updateTypeFn" okText="确认" cancelText="取消">
            <a-form class="sec" style="padding:0 20px" :label-col="{ span: 5 }" :wrapper-col="{ span: 10 }">
                <a-select show-search placeholder="分配类型" v-model="typeObj.type" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                    <a-select-option v-for="(item, index) in add.typeList" :key="index" :value="item">
                        {{ item}}
                    </a-select-option>
                </a-select>
            </a-form>
        </a-modal>

        <Create :d='add' v-if="add.show" @visible="visibleFn" @getCarlist='getRechargeList' />

    </div>

</template>

<style lang='scss' scoped>
</style>

<script>
import Create from "./addRemarks.vue";
const columns = [
    // {
    //     title: "#",
    //     dataIndex: "id",
    // },
    {
        title: "咨询类型",
        dataIndex: "type",
    },
    {
        title: "咨询单位/人",
        dataIndex: "user_name",
    },
    {
        title: "咨询内容",
        scopedSlots: {
            customRender: "content_a",
        },
    },
    {
        title: "咨询时间",
        dataIndex: "inquiry_at",
    },
    // {
    //     title: "指派时间",
    //     dataIndex: "assign_at",
    // },
    {
        title: "服务律师",
        dataIndex: "lawyer_name",
    },
    {
        title: "回复时间",
        dataIndex: "replayed_at",
    },
    {
        title: "回复内容",
        dataIndex: "lawyer_reply",
    },

    // {
    //     title: "用户评价",
    //     dataIndex: "judge",
    // },
    {
        title: "用户评价",
        dataIndex: "judge_star",
    },
    // {
    //     title: "评价时间",
    //     dataIndex: "judge_at",
    // },
    {
        title: "备注",
        dataIndex: "remarks",
    },
    {
        title: "操作",
        dataIndex: "caozuo",
        scopedSlots: {
            customRender: "caozuo",
        },
    },
];
export default {
    components: { Create },
    data() {
        return {
            columns,
            list: [],
            pages: {
                now_page: 1,
                all_page: 1,
                amount: 0,
            },
            MajorTypeList: [],
           
            recharge_money: 0,
            editInquiry: {
                show: false,
                id: null,
                lawyer_name: "",
                lawyer_id: "",
                type:'',
                content:'',
                inquiry_at:null
              
            },
            editRemarks: {
                show: false,
                id: null,
                remarks: "",
            },

            typeObj: {
                show: false,
                id: null,
                type: "",
            },
            add: {
                show: false,
                typeList: [
                    "婚姻家庭",
                    "交通事故",
                    "刑事辩护",
                    "劳动工伤",
                    "土地房产",
                    "债权债务",
                    "合同事务",
                    "医疗纠纷",
                    "公司经营",
                    "损害赔偿",
                    "知识产权",
                    "其他",
                ],
                lawyerList: [],
            },
            search: {
                spe_name: "",
                user_name:'',
                lawyer_id:'',
                start_time:null,
                end_at:null
            },
        };
    },
    created() {
        this.getRechargeList();
        this.getLawyerList();
        this.getMajorTypeList();
    },
    methods: {
        addFn() {
            this.add.show = true;
        },
        clear() {
            this.search = {
                spe_name: "",
                user_name:'',
                lawyer_id:'',
                start_time:null,
                end_at:null
            };

            this.getRechargeList();
        },
        async release(id,type,content,time){
            if(!type){
                alert('请先分配咨询类型')
            }else{
                const r = await this.$axios.post("/customer/release", {
                    id: id,
                    type:type,
                    content:content,
                    time:time,
                });

                if (r.status == 1) {
                    this.getRechargeList();
                }
            }
        },
        searchData() {
            this.pages.now_page = 1;
            this.getRechargeList();
        },
        visibleFn() {
            this.add.show = false;
        },
        lawyerFn(name) {
            this.editInquiry.lawyer_name = name;
        },
        remarksFn(id) {
            this.editRemarks.id = id;
            this.editRemarks.show = true;
        },
        async getMajorTypeList() {
            const r = await this.$axios.post("/customer/getMajorType");
            this.MajorTypeList = r.list;
        },
        async getLawyerList() {
            const that = this;
            const r = await that.$axios.post("/customer/getLawyerList", {
                search: that.search,
            });

            that.add.lawyerList = r.list;
            // this.pages = r.pages;
            // this.recharge_money = r.money;
        },
        async doEditRemarks() {
            const r = await this.$axios.post(
                "/customer/doEditRemarks",
                this.editRemarks
            );
            if (r.status == 1) {
                this.editRemarks.show = false;
                this.getRechargeList();
            }
        },
        async getRechargeList() {
            const that = this;
            const r = await this.$axios.post("/customer/getInquiryList", {
                page: that.pages.now_page,
                search: that.search
            });

            this.list = r.list;
            // this.pages = r.pages;
            // this.recharge_money = r.money;
        },
        async updateTypeFn() {
            console.log(this.typeObj);
            const r = await this.$axios.post(
                "/customer/doEditCustomerType",
                this.typeObj
            );
            if (r.status == 1) {
                this.typeObj.show = false;
                this.getRechargeList();
            }
        },
        replyFn(id,type,content,inquiry_at) {
            this.editInquiry = {
                show: true,
                id: id,
                type:type,
                content:content,
                inquiry_at:inquiry_at
            };
        },
        typeFn(id) {
            this.typeObj = {
                show: true,
                id: id,
            };
        },
        async doEditInquiry() {
            console.log(this.editInquiry)
            const r = await this.$axios.post(
                "/customer/assignLawyer",
                this.editInquiry
            );
            if (r.status == 1) {
                this.editInquiry.show = false;
                this.getRechargeList();
            }
        },
    },
};
</script>