<template>
    <div class="page">
        <a-form class="ctrl" layout="inline">
            <!-- <a-form-item label="咨询类型">
                <a-select show-search placeholder="咨询类型" v-model="search.spe_name" style="min-width: 200px">
                    <a-select-option v-for="(item, index) in MajorTypeList" :key="index" :value="item.name">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item> -->
            <a-form-item label="咨询人">
                <a-input style="width: 200px" placeholder="请输入姓名" v-model="search.user_name"></a-input>
            </a-form-item>
            <a-form-item label="服务律师">
                <a-select show-search placeholder="选择律师" v-model="search.lawyer_id" style="min-width: 200px">
                    <a-select-option v-for="(item, index) in add.lawyerList" :key="index" :value="item.id">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item label="申请时间">
                <a-date-picker v-model="search.start_at" /> -
                <a-date-picker v-model="search.end_at" />
            </a-form-item>

            <a-form-item>
                <a-button type="primary" @click="searchData">搜索</a-button>
                <a-button type="default" @click="clear" style="margin: 0 10px">清除</a-button>
            </a-form-item>
        </a-form>

        <!-- <a-form class="ctrl" layout="inline">
            <a-form-item>
                <a-button type="primary" @click="addFn"> 添加 </a-button>
            </a-form-item>
        </a-form> -->

        <a-table class="sec" size="small" :rowKey="(record) => record.id" bordered :columns="columns" :data-source="list" :pagination="false" :scroll="{ x: 200 }">

            <template slot="content_a" slot-scope="text, record">
                {{record.content}}
            </template>
            <template slot="caozuo" slot-scope="text, record">
                <a-button @click="replyFn(record.id,record.contrac_name,record.start_at)" size="small">指派律师</a-button>
                <!-- <a-button @click="typeFn(record.id)" size="small">分配类型</a-button>
                <a-button @click="remarksFn(record.id)" size="small">客服备注</a-button> -->
                <a-popconfirm  title="确定要发布吗" @confirm="release(record.id,record.type,record.content,record.inquiry_at)" okText="确定" cancelText="取消">
                    <a-button  size="small">发布咨询</a-button>
                </a-popconfirm>

                <a-popconfirm title="确定要删除吗" @confirm="delFn(record.id)" okText="确定" cancelText="取消">
                    <a-button style="margin-left: 5px"  size="small">
                        删除
                    </a-button>
                </a-popconfirm>
            </template>
           
        </a-table>

        <a-pagination class="pagination" showQuickJumper v-model="pages.now_page" :defaultPageSize="50" :total="pages.amount" @change="getContractList" />
      
        <a-modal title="指派律师" v-model="editInquiry.show" @ok="doEditInquiry" okText="确认" cancelText="取消">
            <a-form class="sec" style="padding:0 20px" :label-col="{ span: 5 }" :wrapper-col="{ span: 10 }">
                <a-form-item label="选择律师" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                    <a-select show-search placeholder="选择律师" v-model="editInquiry.lawyer_id" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                        <a-select-option v-for="(item, index) in add.lawyerList" :key="index" :value="item.id">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <!-- <a-form-item label="备注" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                    <a-textarea style="min-height:200px" v-model="editInquiry.service_bz" placeholder="请输入回复内容" autoSize />
                </a-form-item> -->
            </a-form>
        </a-modal>

        
    </div>
</template>

<style lang='scss' scoped>
</style>

<script>
const columns = [
   
    
    {
        title: "申请单位/人",
        dataIndex: "user_name",
    },
   
    {
        title: "合同名称",
        dataIndex: "contrac_name",
    },
    {
        title: "申请时间",
        dataIndex: "start_at",
    },
    {
        title: "服务律师",
        dataIndex: "lawyer_name",
    },
    
    {
        title: "操作",
        dataIndex: "caozuo",
        scopedSlots: {
            customRender: "caozuo",
        },
    },
];
export default {
    // components: { Create },
    data() {
        return {
            columns,
            list: [],
            pages: {
                now_page: 1,
                all_page: 1,
                amount: 0,
            },
            MajorTypeList: [],
           
            recharge_money: 0,
            editInquiry: {
                show: false,
                id: null,
                lawyer_id: "",
                name:'',
                time:''
            },

            typeObj: {
                show: false,
                id: null,
                type: "",
            },
            add: {
                show: false,
                lawyerList: [],
            },
            search: {
                // spe_name: "",
                user_name:'',
                lawyer_id:'',
                start_at:null,
                end_at:null
            },
        };
    },
    created() {
        this.getContractList();
        this.getLawyerList();
    },
    methods: {
        addFn() {
            this.add.show = true;
        },
        clear() {
            this.search = {
                // spe_name: "",
                user_name:'',
                lawyer_id:'',
                end_at:null,
                start_at:null
            };

            this.getContractList();
        },
        async release(id,type,content,time){
            if(!type){
                alert('请先分配咨询类型')
            }else{
                const r = await this.$axios.post("/customer/release", {
                    id: id,
                    type:type,
                    content:content,
                    time:time,
                });

                if (r.status == 1) {
                    this.getContractList();
                }
            }
        },
        searchData() {
            this.pages.now_page = 1;
            this.getContractList();
        },
        visibleFn() {
            this.add.show = false;
        },
        async delFn(id) {
            const that = this;
            const r = await that.$axios.post("/inquiry/delContract", {
                id: id
            });
            if(r.status == 1){
                this.getContractList();
            }
        },
       
        async getLawyerList() {
            const that = this;
            const r = await that.$axios.post("/customer/getLawyerList", {
                search: that.search,
            });

            that.add.lawyerList = r.list;
            // this.pages = r.pages;
            // this.recharge_money = r.money;
        },
        
        async getContractList() {
            const that = this;
            const r = await this.$axios.post("/inquiry/getContractList", {
                page: that.pages.now_page,
                search: that.search
            });

            this.list = r.list;
            this.pages.amount = r.amount;
            // this.pages = r.pages;
            // this.recharge_money = r.money;
        },
        replyFn(id,name,time) {
            this.editInquiry = {
                show: true,
                id: id,
                name:name,
                time:time
            };
        },
        typeFn(id) {
            this.typeObj = {
                show: true,
                id: id,
            };
        },
        async doEditInquiry() {
            const r = await this.$axios.post(
                "/inquiry/contractLawyer",
                this.editInquiry
            );
            if (r.status == 1) {
                this.editInquiry.show = false;
                this.getContractList();
            }
        },
    },
};
</script>