<template>
  <header class="header">
    <a-icon
      class="trigger"
      @click="collapse"
      :type="$store.state.collapsed ? 'menu-unfold' : 'menu-fold'"
    />

    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item
        v-for="(menu, index) in $store.state.activeMenu.breadcrumbs"
        :key="index"
        >{{ menu }}</a-breadcrumb-item
      >
    </a-breadcrumb>

    <a-dropdown v-if="$store.state.user.id">
      <span class="username">
        <span>{{$store.state.user.group_name}}</span><span>{{$store.state.user.name}}</span>
        <a-icon type="down" />
      </span>
      <a-menu slot="overlay">
        <a-menu-item @click="updatePsw">修改密码</a-menu-item>
        <a-menu-item @click="logout">退出登录</a-menu-item>
      </a-menu>
    </a-dropdown>
  </header>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
  methods: {
    collapse() {
      this.$store.commit("collapse");
    },
    updatePsw () {
      this.$router.push('/user/updatePsw')
    },
    logout() {
      localStorage.clear();
      this.$store.commit("saveToken", null);
      this.$store.commit("saveUserData", {});
      this.$router.push("/user/login");
    },
  },
};
</script>