<template>
  <div class="page">
    <div class="article">
      <h3>{{ article.title }}</h3>

      <!-- <div class="info">
        <span class="creater">{{ article.content }}</span>
        <span class="time">{{ article.created_at }}</span>
      </div>

      <div class="desc" v-if="article.desc">{{ article.desc }}</div> -->

      <div class="content" v-html="article.content"></div>

      <!-- <div class="link" v-if="article.link">
        <b>外部链接：</b><a :href="article.link" target="_blank">访问</a>
      </div>

      <div class="link" v-if="article.files">
        <b>下载附件：</b>
        <div>
          <div v-for="(file, index) in article.files" :key="index">
            <a :href="API + file.file_path" target="_blank"
              ><a-icon type="paper-clip" /> {{ file.file_name }}
            </a>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<style lang="scss" scoped>
.link {
  margin-right: 10px;
}
</style>

<script>
export default {
  data() {
    return {
      article: {},
    };
  },
  async created() {
    const id = this.$route.query.id;
    const r = await this.$axios.get("/article/getArticleDetail?id=" + id);
    console.log(r);
    if (r.status = 1) {
      this.article = r.article;
    } else {
      this.$message.error(r.msg);
    }
  },
};
</script>
