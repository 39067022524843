<template>
    <a-modal class="modal" width='300px' :title="name" okText="确定" cancelText="取消" @cancel='cancelFn' v-model="visible" @ok="doAdd">
        <a-form>
          <div v-if="key =='style'">
            <a-form-item label="配置" style="display: flex">
                <a-input type="text" v-model="form.val"></a-input>
            </a-form-item>
            <a-form-item label="价格" style="display: flex">
                <a-input type="text" v-model="form.val1"></a-input>
            </a-form-item>
          </div>
            <a-form-item v-else label="名称" style="display: flex">
                <a-input type="text" v-model="form.val"></a-input>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
    props: ["d"],
    data() {
        return {
            visible: false,
            form: {},
            name:'',
            key:''
        };
    },
    created() {
        console.log(this.d.key+'aaaaaaaaaaaaaa');
        this.visible = this.d.show;
        this.name = this.d.title;
        this.form = { ...this.d };
        this.key = this.d.key
        console.log(this.visible)
    },
    methods: {
        async doAdd() {
            const r = await this.$axios.post("/setting/car/doAdd", this.form);
            if(r.status == 1){
              this.$emit('visible',false)
              this.$emit('getCarlist',this.key,r.id)
            }
        },
        cancelFn(){
          this.$emit('visible',false)
          console.log(this.visible)
        }
    },
};
</script>