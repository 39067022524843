<template>
  <div class="page">
    <a-form
      class="form sec"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 8 }"
    >
      <a-form-item label="账号">
        <div class="flex-item">
          <a-input
            v-model="form.username"
            placeholder="如不修改账号请不要填写"
          ></a-input>
        </div>
      </a-form-item>
      <a-form-item label="原密码">
        <div class="flex-item">
          <a-input
            type="password"
            v-model="form.old_password"
            placeholder="请输入原密码"
          ></a-input>
        </div>
      </a-form-item>
      <a-form-item label="新密码">
        <div class="flex-item">
          <a-input
            type="password"
            v-model="form.new_password"
            placeholder="请输入新密码"
          ></a-input>
        </div>
      </a-form-item>
      <a-form-item label="确认新密码">
        <div class="flex-item">
          <a-input
            type="password"
            v-model="form.new_password2"
            placeholder="请确认新密码"
          ></a-input>
        </div>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 8, offset: 5 }">
        <a-button type="primary" @click="sub">提交修改</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<style lang='scss' scoped>
.flex-item {
  display: flex;
}
.sec {
  margin: 15px;
  padding: 40px;
}
</style>

<script>
export default {
  data() {
    return {
      form: {
        username: "",
        old_password: "",
        new_password: "",
        new_password2: "",
      },
    };
  },
  async created() {
    const r = await this.$axios.get("/user/getUserInfo");
    console.log(r);
    this.form.username = r.info.username;
  },
  methods: {
    async sub() {
      if (this.form.new_password) {
        if (!this.form.old_password) {
          this.$message.error("请输入原密码");
          return;
        }
        if (this.form.new_password.length < 6) {
          this.$message.error("请输入至少6个字符的新密码");
          return;
        }
        if (!this.form.new_password2) {
          this.$message.error("请再次输入新密码");
          return;
        }
        if (this.form.new_password !== this.form.new_password2) {
          this.$message.error("两次输入的新密码不一致");
          return;
        }
      }

      const r = await this.$axios.post("/user/doUpdatePassword", {
        username: this.form.username,
        old_password: this.form.old_password,
        new_password: this.form.new_password,
      });

      if (r.status == 1) {
        localStorage.clear();
        this.$store.commit("saveToken", null);
        this.$router.push("/user/login");
      }
    },
  },
};
</script>