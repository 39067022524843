<template>
    <div class="component-wrap">
        <a-modal class="modal" width='40%' title="添加咨询" okText="确定" cancelText="取消" @cancel='cancelFn' v-model="visible" @ok="sub">
            <a-form class="form sec" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                <a-form-item label="联系方式">
                    <a-input style="width: 60%;" v-model="form.tel" placeholder="请输入联系方式">
                    </a-input>
                    <a-button type="primary" style="margin-left:10px" @click="retrievalFn"> 检索 </a-button>
                    <i class="must">*</i>
                </a-form-item>
                <a-form-item label="名称">
                    <a-input style="width: 60%;" disabled  v-model="form.name" >
                    </a-input>
                    <i class="must">*</i>
                </a-form-item>
                <a-form-item label="案件类型">
                    <a-select show-search style="width: 60%;" placeholder="分配类型" v-model="form.type">
                        <a-select-option v-for="(item, index) in typeList" :key="index" :value="item">
                            {{ item}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="指派律师">
                    <a-select show-search style="width: 60%;" placeholder="指派律师" v-model="form.lawyer_id">
                        <a-select-option v-for="(item, index) in lawyerList" :key="index" :value="item.id" @click="lawyerFn(item.name)">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="咨询内容">
                    <a-textarea style="width: 60%" v-model="form.content" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 6 }" />
                </a-form-item>
                <a-form-item label="客服备注">
                    <a-textarea style="width: 60%" v-model="form.desc" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 6 }" />
                </a-form-item>
            </a-form>

            
             <a-modal class="modal" :footer="null" centered v-model="show" title="添加咨询" okText="确定" cancelText="取消" >
                 <a-table class="sec " size="small" :rowKey="(record) => record.id" bordered :columns="columns" :data-source="userList" :pagination="false">
                   <template slot="fszx" slot-scope="text, record">
                        <span v-if="record.fszx == -2" color="red">不限</span>
                        <span v-else color="red">{{record.fszx}}</span>
                    </template>
                    <template slot="caozuo" slot-scope="text, record">
                        <a-button @click="replyFn(record)" size="small">选择</a-button>
                    </template>
                </a-table>
             </a-modal>
            
        </a-modal>
    </div>
</template>
  
  <style lang='scss' scoped>
hr {
    border-top: none;
    margin: 40px;
}

h4 {
    margin-left: 20vw;
}
.component-wrap {
    position: relative;
}

.retrieval {
    width: 500px;
    min-height: 100px;
    position: absolute;
    top: 200px;
    left: calc(50% - 250px);
    background: #ffffff;
}
</style>
  
  <script>
const columns = [
    {
        title: "姓名/单位",
        dataIndex: "name",
    },
    {
        title: "剩余咨询次数",
        dataIndex: "fszx",
        scopedSlots: {
            customRender: "fszx",
        },
    },
    {
        title: "操作",
        dataIndex: "caozuo",
        scopedSlots: {
            customRender: "caozuo",
        },
    },
];
export default {
    props: ["d"],
    data() {
        return {
            columns,
            formItemLayout: {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 20,
                },
            },
            form: {
                lawyer_id: "",
                user_id:'',
                name:'',
                tel:'',
                num:0
            },
            visible: false,
            typeList: [],
            lawyerList: [],
            userList: [],
            show:false
        };
    },
    mounted() {},
    created() {
        this.visible = this.d.show;
        this.typeList = this.d.typeList;
        this.lawyerList = this.d.lawyerList;
        console.log(this.lawyerList);
        // console.log(this.typeList);
    },
    methods: {
        cancelFn() {
            this.$emit("visible", false);
            console.log(this.visible);
        },
        replyFn(item){
            this.form = {
                user_id : item.id,
                name : item.name,
                tel : item.tel,
                num:item.fszx
            }
            this.show = false
        },
        //检索
        async retrievalFn() {
            if (!this.form.tel) return this.$message.error("请输入联系方式");
            this.show = true
            const r = await this.$axios.post(
                "/marketing/doRetrieval",
                this.form
            );
            // 提交表单
            if (r.status == 1) {
                this.userList = r.data;
                // this.$emit('visible',false)
                // this.$emit('getCarlist')
            }
        },
        lawyerFn(id) {
            this.form.lawyer_name = id;
        },
        async sub() {
            console.log(this.form);
            if (!this.form.name) return this.$message.error("请输入姓名");
            const r = await this.$axios.post(
                "/customer/addInquiry",
                this.form
            );
            // 提交表单
            if (r.status == 1) {
                this.$emit('visible',false)
                this.$emit('getCarlist')
            }
        },
    },
};
</script>