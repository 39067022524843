<template>
  <div class="page">
    <a-form
      class="form sec"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 10 }"
    >
      <h4>账户信息</h4>
      <a-form-item label="姓名">
        <a-input
          style="width: 60%"
          v-model="form.name"
          @change="inputName"
          placeholder="请输入姓名"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="手机号">
        <a-input
          style="width: 60%"
          v-model="form.tel"
          placeholder="请输入手机号"
          @change="inputTel"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="登录账号">
        <a-input
          style="width: 60%"
          v-model="form.username"
          placeholder="请输入登录账号"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="初始密码">
        <a-input
          type="password"
          style="width: 60%"
          v-model="form.password"
          placeholder="请输入密码"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="确认密码">
        <a-input
          type="password"
          style="width: 60%"
          v-model="form.password2"
          placeholder="再次输入密码"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>

      <hr />
      <h4>保险类型及保费</h4>
      <a-form-item label="类型">
        <a-checkbox-group v-model="form.type">
          <a-checkbox :value="1">投保</a-checkbox>
          <a-checkbox :value="2">申报</a-checkbox>
        </a-checkbox-group>
        <i class="must">*</i>
      </a-form-item>

      <a-form-item v-if="form.type.includes(2)" label="申报险种">
        <a-radio-group v-model="form.planindex">
          <a-radio :value="2">综合险</a-radio>
          <a-radio :value="1">基本险</a-radio>
          <a-radio :value="3">易碎险</a-radio>
        </a-radio-group>
        <i class="must" v-if="form.type.includes(2)">*</i>
      </a-form-item>

      <a-form-item label="综合险费率">
        <a-input-number
          style="width: 100px"
          v-model="form.rate2"
          placeholder="请输入费率"
          :min="0"
        ></a-input-number>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="基本险费率">
        <a-input-number
          style="width: 100px"
          v-model="form.rate1"
          placeholder="请输入费率"
          :min="0"
        ></a-input-number>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="易碎险费率">
        <a-input-number
          style="width: 100px"
          v-model="form.rate3"
          placeholder="请输入费率"
          :min="0"
        ></a-input-number>
        <i class="must">*</i>
      </a-form-item>

      <hr />
      <h4>投保人信息</h4>
      <a-form-item label="公司名称">
        <a-input
          style="width: 60%"
          v-model="form.applicant_name"
          placeholder="请输入投保人公司全称"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>

      <a-form-item label="统一社会信用代码">
        <a-input
          style="width: 60%"
          v-model="form.applicant_certificateNo"
          placeholder="请输入投保人统一社会信用代码"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="联系人">
        <a-input
          style="width: 60%"
          v-model="form.applicant_linkManName"
          placeholder="请输入投保人联系人"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="联系电话">
        <a-input
          style="width: 60%"
          v-model="form.applicant_mobileTelephone"
          placeholder="请输入投保人联系电话"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="地址">
        <a-input
          style="width: 60%"
          v-model="form.applicant_address"
          placeholder="请输入投保人地址"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>

      <a-form-item label="Email">
        <a-input
          style="width: 60%"
          v-model="form.applicant_email"
          placeholder="请输入投保人Email"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>

      <hr />
      <h4>被保险人信息</h4>
      <a-form-item label="被保险人">
        <a-input
          style="width: 60%"
          v-model="form.insurant_name"
          @change="input_insurant_name"
          placeholder="请输入被保险人"
        ></a-input>
        <i class="must" v-if="form.type.includes(2)">*</i>
      </a-form-item>
      <a-form-item label="被保险人类型">
        <a-radio-group
          v-model="form.insurant_personnelType"
          @change="selectPersonnelType"
        >
          <a-radio :value="'1'">个人</a-radio>
          <a-radio :value="'0'">团体</a-radio>
        </a-radio-group>
        <i class="must" v-if="form.type.includes(2)">*</i>
      </a-form-item>
      <a-form-item label="证件类型">
        <a-radio-group v-model="form.insurant_certificateType">
          <a-radio :value="'01'">身份证号</a-radio>
          <a-radio :value="'03'">统一社会信用代码</a-radio>
        </a-radio-group>
        <i class="must" v-if="form.type.includes(2)">*</i>
      </a-form-item>
      <a-form-item label="证件号码">
        <a-input
          style="width: 60%"
          v-model="form.insurant_certificateNo"
          placeholder="请输入被保险人证件号码"
          @change="inputIdCard"
        ></a-input>
        <i class="must" v-if="form.type.includes(2)">*</i>
      </a-form-item>

      <a-form-item
        label="被保险人生日"
        v-if="form.insurant_personnelType == '1'"
      >
        <a-date-picker v-model="form.insurant_birthday" />
        <i class="must" v-if="form.type.includes(2)">*</i>
      </a-form-item>

      <a-form-item
        label="被保险人性别"
        v-if="form.insurant_personnelType == '1'"
      >
        <a-radio-group v-model="form.insurant_sexCode">
          <a-radio value="M">男</a-radio>
          <a-radio value="F">女</a-radio>
        </a-radio-group>
        <i class="must" v-if="form.type.includes(2)">*</i>
      </a-form-item>
      <a-form-item label="联系人">
        <a-input
          style="width: 60%"
          v-model="form.insurant_linkManName"
          placeholder="请输入被保险人联系人"
        ></a-input>
        <i class="must" v-if="form.type.includes(2)">*</i>
      </a-form-item>
      <a-form-item label="联系电话">
        <a-input
          style="width: 60%"
          v-model="form.insurant_mobileTelephone"
          placeholder="请输入被保险人联系电话"
        ></a-input>
        <i class="must" v-if="form.type.includes(2)">*</i>
      </a-form-item>
      <a-form-item label="地址">
        <a-input
          style="width: 60%"
          v-model="form.insurant_address"
          placeholder="请输入被保险人地址"
        ></a-input>
        <i class="must" v-if="form.type.includes(2)">*</i>
      </a-form-item>
      <a-form-item label="Email">
        <a-input
          style="width: 60%"
          v-model="form.insurant_email"
          placeholder="请输入被保险人邮箱"
        ></a-input>
        <i class="must" v-if="form.type.includes(2)">*</i>
      </a-form-item>

      <hr />
      <h4>备注</h4>
      <a-form-item label="备注">
        <a-textarea
          style="width: 60%"
          v-model="form.remarks"
          placeholder="请输入备注"
          :auto-size="{ minRows: 3, maxRows: 6 }"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 10, offset: 5 }">
        <a-button type="primary" @click="sub">提交</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<style lang='scss' scoped>
hr {
  border-top: none;
  margin: 40px;
}
h4 {
  margin-left: 40px;
}
</style>

<script>
import moment from "moment";
export default {
  data() {
    return {
      form: {
        pid: 0,
        name: "",
        tel: "",
        username: "",
        password: "",
        password2: "",

        type: [1],
        rate1: 0.00012,
        rate2: 0.00015,
        rate3: 0.00032,
        // 投保人
        applicant_name: "",
        applicant_certificateNo: "",
        applicant_linkManName: "",
        applicant_mobileTelephone: "",
        applicant_address: "",
        applicant_email: "",
        // 被保险人
        insurant_name: "",
        insurant_personnelType: "1",
        insurant_certificateType: "01",
        insurant_certificateNo: "",
        insurant_birthday: "",
        insurant_sexCode: "",
        insurant_linkManName: "",
        insurant_mobileTelephone: "",
        insurant_address: "",
        insurant_email: "",
        remarks: "",

        planindex: 0,
      },
    };
  },
  mounted() {
    if (this.$route.query.pid) {
      this.form.pid = this.$route.query.pid;
      this.getInfo();
    }
  },
  methods: {
    inputTel() {
      this.form.insurant_mobileTelephone = this.form.tel;
    },
    async getInfo() {
      const r = await this.$axios.get("/customer/getInfo?id=" + this.form.pid);
      if (r.status == 1) {
        this.form.applicant_name = r.info.applicant_name;
        this.form.applicant_certificateNo = r.info.applicant_certificateNo;
        this.form.applicant_linkManName = r.info.applicant_linkManName;
        this.form.applicant_mobileTelephone = r.info.applicant_mobileTelephone;
        this.form.applicant_address = r.info.applicant_address;
        this.form.applicant_email = r.info.applicant_email;
      }
    },
    inputName() {
      this.form.insurant_linkManName = this.form.insurant_name = this.form.name;
    },
    input_insurant_name() {
      if (this.form.insurant_personnelType == "1") {
        this.form.insurant_linkManName = this.form.insurant_name;
      }
    },
    inputIdCard() {
      if (this.form.insurant_personnelType == "1") {
        const no = this.form.insurant_certificateNo;
        if (no.length < 18) return;
        let birthday = no.slice(6, 14);
        this.form.insurant_birthday = moment(birthday);
      }
    },
    selectPersonnelType() {
      if (this.form.insurant_personnelType == "0") {
        this.form.insurant_certificateType = "03";
      } else {
        this.form.insurant_certificateType = "01";
        this.form.insurant_linkManName = this.form.insurant_name;
      }
    },

    async sub() {
      console.log(this.form);
      if (!this.form.name) return this.$message.error("请输入姓名");
      if (!this.form.tel) return this.$message.error("请输入手机号");
      if (!this.form.username) return this.$message.error("请输入用户名");
      if (!this.form.password) return this.$message.error("请输入密码");

      if (this.form.password.length < 6)
        return this.$message.error("请输入6位数以上密码");
      if (!this.form.password2) return this.$message.error("请再次输入密码");
      if (this.form.password != this.form.password2)
        return this.$message.error("两次输入的密码不一致");

      if (this.form.type.length == 0) return this.$message.error("请选择类型");
      if (this.form.rate1 <= 0 || this.form.rate2 <= 0 || this.form.rate3 <= 0)
        return this.$message.error("请输入费率");

      if (!this.form.applicant_name)
        return this.$message.error("请输入投保人单位全称");
      if (!this.form.applicant_certificateNo)
        return this.$message.error("请输入投保人统一社会信用代码");
      if (!this.form.applicant_linkManName)
        return this.$message.error("请输入投保人联系人");
      if (!this.form.applicant_mobileTelephone)
        return this.$message.error("请输入投保人联系电话");
      if (!this.form.applicant_address)
        return this.$message.error("请输入投保人地址");
      if (!this.form.applicant_email)
        return this.$message.error("请输入投保人邮箱");

      if (this.form.type.includes(2)) {
        if (!this.form.planindex) return this.$message.error("请选择申报险种");
        if (!this.form.insurant_name)
          return this.$message.error("请输入被保险人");
        if (!this.form.insurant_personnelType)
          return this.$message.error("请选择被保险人类型");
        if (!this.form.insurant_certificateType)
          return this.$message.error("请选择被保险人证件类型");
        if (!this.form.insurant_certificateNo)
          return this.$message.error("请输入被保险人证件号码");

        if (this.form.insurant_personnelType == "1") {
          if (!this.form.insurant_birthday)
            return this.$message.error("请输入被保险人出生年月");
          if (!this.form.insurant_sexCode)
            return this.$message.error("请选择被保险人性别");
          if (!this.form.insurant_linkManName)
            return this.$message.error("请输入被保险人联系人");
        }

        if (!this.form.insurant_mobileTelephone)
          return this.$message.error("请输入被保险人联系电话");
        if (!this.form.insurant_address)
          return this.$message.error("请输入被保险人地址");
        if (!this.form.insurant_email)
          return this.$message.error("请输入被保险人邮箱");
      }

      const r = await this.$axios.post("/customer/create", this.form);
      // 提交表单
      if (r.status == 1) {
        this.$router.push("/customer/list");
      }
    },
  },
};
</script>