<template>
    <div class="flex-page">
        <a-form class="form" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
            <a-form-item label="选择分类">
                <a-select showSearch placeholder="选择分类" optionFilterProp="children" style="width: 200px" v-model="form.type">
                <a-select-option value="1">知识讲堂</a-select-option>
                <a-select-option value="2">热门问答</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item label="选择标签">
                <a-select showSearch placeholder="选择标签" optionFilterProp="children" style="width: 200px" v-model="form.major_id">
                <a-select-option :value="0">请选择</a-select-option>
                <a-select-option v-for="(item, key) in cateList" :key="key" :value="item.id">{{ item.name }}
                </a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item label="文章标题">
                <a-input type="text" style="width: 600px" v-model="form.title"></a-input>
            </a-form-item>

            <a-form-item label="文章封面">
                <UploadFile @callback="uploaded" :mult="true" folder="article" :oldFiles='form.src'/>
            </a-form-item>
            <a-form-item label="文章内容">
                <Editor v-if="data_got" @callback="updateEditor" :data="form.content" folder="article"  />
            </a-form-item>
            
        </a-form>
        <div class="buttons">
            <a-button type="primary" @click="sub">提交保存</a-button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.files {
    margin-top: 20px;
    .file {
        height: 32px;
        line-height: 24px;
    }
}
</style>

<script>
import UploadFile from "../../components/uploadFile";
import Editor from "../../components/editor";

export default {
    components: { UploadFile, Editor },
    data() {
        return {
            formItemLayout: {
                labelCol: {
                    span: 3,
                },
                wrapperCol: {
                    span: 20,
                },
            },
            cateList: [],
            data_got: false,
            type: 0,
            form: {
                type: 0,
                cate_id: 0,
                title: "",
                content: "",
                files: "",
            },
        };
    },
    async created() {
        this.form.type = this.$route.query.type;
      // 获取所有的资料分类
      const r = await this.$axios.post(
        "/customer/getMajorType" 
      );
      this.cateList = r.list;
    },
    async mounted() {
        const id = this.$route.query.id;
        const r = await this.$axios.get("/article/getArticleDetail?id=" + id);

        if ((r.status = 1)) {
            this.form = r.article;
            this.form.src = JSON.parse(this.form.src)
            console.log(this.form.src)
            this.data_got = true;
        } else {
            this.$message.error(r.msg);
        }
    },
    methods: {
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },
        async sub() {
            const that = this;
            
            if (!that.form.title) {
                that.$message.error("请填写标题");
                return;
            }
            const r = await that.$axios.post(
                "/article/updateArticle",
                this.form
            );

            if (r.status == 1) {
                that.$router.push("/article/list" );
            }
        },

        uploaded(files) {
            console.log(JSON.stringify(files));
            this.form.files = files;
        },
        updateEditor(data) {
            this.form.content = data;
        },
    },
};
</script>