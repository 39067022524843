<template>
  <aside class="aside">
    <div class="logo" @click="openKeys = []">
      <img src="/logo.png" alt="" />
      <div>闻道有法 <br />管理后台</div>
    </div>
    <a-menu
      class="nav"
      theme="dark"
      mode="inline"
      :inline-collapsed="$store.state.collapsed"
      v-model="$store.state.activeMenu.selectedKeys"
      :open-keys.sync="$store.state.activeMenu.openKeys"
    >
      <template v-for="(menu, n) in $store.state.menus">
        <a-sub-menu v-if="menu.children" :key="menu.id">
          <span slot="title"
            ><a-icon :type="menu.icon" /><span>{{ menu.title }}</span></span
          >
          <a-menu-item
            v-for="(subMenu, s) in menu.children"
            :key="subMenu.id"
            @click="goto(subMenu)"
            >{{ subMenu.title }}</a-menu-item
          >
        </a-sub-menu>
        <a-menu-item v-else :key="menu.id" @click="goto(menu)">
          <a-icon :type="menu.icon" />
          <span>{{ menu.title }}</span>
        </a-menu-item>
      </template>
    </a-menu>
  </aside>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
  data() {
    return {
      navList: [],
      selectedKeys: [],
      openKeys: [],
    };
  },

  methods: {
    goto(nav) {
      this.$router.push(nav.path);
    },
  },
};
</script>