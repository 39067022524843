<template>
  <div class="page">
    <a-form class="ctrl" layout="inline">
      <a-form-item label="用户">
        <a-input
          style="width: 200px"
          placeholder="请输入姓名"
          v-model="search.name"
        ></a-input>
      </a-form-item>
      <a-form-item label="手机号">
        <a-input
          style="width: 200px"
          placeholder="请输入号码"
          v-model="search.tel"
        ></a-input>
      </a-form-item>

      <a-form-item label="充值时间">
        <a-date-picker v-model="search.start_time" /> -
        <a-date-picker v-model="search.end_time" />
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="searchData">搜索</a-button>
        <a-button type="default" @click="clear" style="margin: 0 10px">清除</a-button>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-form-item>
    </a-form>

    <div class="statistics">共{{ pages.amount }}条，共 {{recharge_money}} 元</div>

    <a-table
      class="sec"
      size="small"
      :rowKey="(record) => record.id"
      bordered
      :columns="columns"
      :data-source="list"
      :pagination="false"
    >
      <template slot="place" slot-scope="text, record">
        {{record.fromPortDesc}} -- {{record.toPortDesc}}
      </template>

      <template slot="status" slot-scope="text, record">
        <a-tag v-if="record.status == -1" color="red">已取消</a-tag>
        <a-tag v-if="record.status == 0" color="orange">未支付</a-tag>
        <a-tag v-if="record.status == 1" color="green">已支付</a-tag>
        <a-tag v-if="record.status == 2" color="orange">已发货</a-tag>
      </template>
    </a-table>

    <a-pagination class="pagination" showQuickJumper v-model="pages.now_page" :defaultPageSize="50"
      :total="pages.amount" @change="getRechargeList" />
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
  },
  {
    title: "姓名",
    dataIndex: "customer_name",
  },
  {
    title: "手机",
    dataIndex: "customer_tel",
  },
  {
    title: "充值金额",
    dataIndex: "money",
  },
  {
    title: "充值时间",
    dataIndex: "recharged_at",
  },
  {
    title: "操作人",
    dataIndex: "admin_name",
  },
];
export default {
  data() {
    return {
      columns,
      list: [],
      pages: {
        now_page: 1,
        all_page: 1,
        amount: 0
      },
      search: {
        who: null,
        tel: "",
        name: "",
        start_time: null,
        end_time: null
      },
      recharge_money: 0
    };
  },
  created() {
    if(this.$route.query.who) this.search.who = this.$route.query.who;
    this.getRechargeList();
  },
  methods: {
    clear () {
      this.search = {
        who: null,
        tel: "",
        name: "",
        start_time: null,
        end_time: null
      };

      this.getRechargeList()
    },
    searchData() {
      this.pages.now_page = 1;
      this.getRechargeList();
    },
    async getRechargeList() {
      const that = this;
      const r = await this.$axios.post("/recharge/getRechargeList", {
        page: that.pages.now_page,
        search: that.search
      });
      console.log(r);

      this.list = r.list;
      this.pages = r.pages;
      this.recharge_money = r.money;
    },
    async exportData () {
      const r = await this.$axios.post("/recharge/exportData", {
        search: this.search
      });

      if(r.status == 1){
        window.open(r.file)
      }
    }
  }
};
</script>