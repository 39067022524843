<template>
  <div class="page">
    <a-form class="ctrl" layout="inline">

      <a-form-item label="类型">
        <a-radio-group v-model="options.type">
          <a-radio value="新车">新车</a-radio>
          <a-radio value="二手车">二手车</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="车牌号">
        <a-input
          style="width: 200px"
          placeholder="请输入车牌号"
          v-model="options.chepai"
        ></a-input>
      </a-form-item>
      <a-form-item label="手机号">
        <a-input
          style="width: 200px"
          placeholder="请输入手机号码"
          v-model="options.tel"
        ></a-input>
      </a-form-item>

      <a-form-item label="投保时间">
        <a-date-picker v-model="options.start_at" /> -
        <a-date-picker v-model="options.end_at" />
      </a-form-item>

      <a-form-item label="状态">
        <a-select style="width: 130px" v-model="options.status">
          <a-select-option
            v-for="(status, index) in statusList"
            :key="index"
            :value="status.id"
            >{{ status.name }}</a-select-option
          >
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="getOrderList">搜索</a-button>
        <a-button type="default" @click="clear" style="margin: 0 10px">清除</a-button>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-form-item>
    </a-form>

    <div class="statistics">共{{ page.amount }}条，保额{{money}}元，保费{{fee}} 元</div>

    <a-table
      class="sec"
      size="small"
      :rowKey="(record) => record.id"
      bordered
      :columns="columns"
      :data-source="list"
      :pagination="false"
      
    >

      <template slot="type" slot-scope="text, record">
        {{record.type}}
      </template>

      <template slot="car" slot-scope="text, record">
        {{record.brand}}{{record.model}}{{record.year}}{{record.style}}
      </template>

      <template slot="buy_price" slot-scope="text, record">
        {{record.buy_money}}
      </template>

    

    </a-table>

    <a-pagination
      class="pagination"
      showQuickJumper
      v-model="page.now_page"
      :defaultPageSize="50"
      :total="page.amount"
      @change="getOrderList"
    />
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
  },
  {
    title: "车辆类型",
    scopedSlots: {
      customRender: "type",
    },
  },
  {
    title: "车辆信息",
    dataIndex: "car",
  },
  {
    title: "车架号",
    dataIndex: "chejia",
  },
  {
    title: "发动机号",
    dataIndex: "fadongji",
  },
  {
    title: "车牌号",
    dataIndex: "chepai",
  },
  {
    title: "购车日期",
    dataIndex: "buy_date",
  },
  {
    title: "购车价格",
    dataIndex: "buy_money",
  },
  {
    title: "服务期限",
    scopedSlots: {
      customRender: "qixian",
    },
  },
  {
    title: "服务费用",
    dataIndex: "fee",
  },
  {
    title: "状态",
    scopedSlots: {
      customRender: "status",
    },
  },
  {
    title: "创建时间",
    dataIndex: "created_at",
  },
  {
    title: "操作",
    scopedSlots: {
      customRender: "action",
    },
  },
];
export default {
  data() {
    return {
      statusList: [
        { id: -10, name: "所有" },
        { id: -1, name: "已删除" },
        { id: 0, name: "暂存" },
        { id: 1, name: "未支付" },
        { id: 2, name: "已支付" }
      ],
      options: {
        type: '新车',
        name: "",
        tel: "",
        start_at: null,
        end_at: null,
        status: -10,
      },
      columns,
      list: [],
      amount: 0,
      money: 0,
      fee: 0,
      page: {
        now_page: 1,
      },
    };
  },
  created() {
    if(this.$route.query.who) this.options.who = this.$route.query.who;
   this.getOrderList();
  },
  methods: {
    clear () {
      this.options = {
        name: "",
        tel: "",
        start_at: null,
        end_at: null,
        status: 20,
      }
    //  this.getOrderList()
    },
    async getOrderList() {
      const options = { ...this.options };
      options.page = this.page.now_page || 1;
      const r = await this.$axios.post("/setting/create/selectList");
       this.list = r.data;
      // this.page = r.pages;
      // this.money = r.money;
      // this.fee = r.fee;
    },
    async exportData () {
      const r = await this.$axios.post("/order/exportData", this.options);

      if(r.status == 1){
        window.open(r.file)
      }
    }
  },
};
</script>