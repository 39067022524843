<template>
    <!-- <div>
    品牌--车型--年款--配置--价格
    <p>东风本田--雅阁--2021-豪华版--21.58</p>

    <ol>
      <li>五列，点选品牌后右侧显示出此品牌的车型列表，点选车型右侧显示年款。。。</li>
      <li>单个添加</li>
      <li>批量导入</li>
      <li>更新</li>
      <li>删除</li>
      <li>添加和编辑都使用弹出modal组件，modal写在pages/car文件夹下</li> addBran
    </ol>
  </div>-->
    <div class="page content">
        <div class="car-brand car-div">
            <div class="brand-title">
                品牌
                <a-tooltip placement="bottom">
                    <template slot="title">
                        <span>添加</span>
                    </template>
                    <a-icon class="img" type="plus-circle" @click="addFn('brand','添加品牌')" />
                </a-tooltip>
            </div>
            <a-list item-layout="horizontal" bordered  :data-source="branList" v-if='branList.length > 0'>
                <a-list-item slot="renderItem" slot-scope="item, key" class="brand-list"   :class="brand_id == item.id?'white':''">
                    <div class="item-title" @click.stop="selectItem('brand', item.id)">{{ item.brand }}</div>
                    <div class="fade">
                        <a-tooltip placement="bottom" class="icon">
                            <template slot="title">
                                <span>编辑</span>
                            </template>
                            <a-icon @click="doEdit('品牌名称', 'brand',item.id,item.brand)" class="icon" type="edit" style="margin-right: 10px" />
                        </a-tooltip>
                        <a-tooltip placement="bottom" class="icon">
                            <template slot="title">
                                <span>删除</span>
                            </template>
                            <a-popconfirm title="确认删除该品牌么？" @confirm="doDelBrand('brand',item.id,key)" okText="确认" cancelText="取消">
                                <a-icon type="delete" />
                            </a-popconfirm>
                        </a-tooltip>
                    </div>
                </a-list-item>
            </a-list>
        </div>
        <!-- 车型 -->
        <div class="car-div" >
            <div class="brand-title">
                车型
                <a-tooltip v-if="brand_id" placement="bottom">
                    <template slot="title">
                        <span>添加</span>
                    </template>
                    <a-icon @click="addFn('model','添加车型')" class="img" type="plus-circle" />
                </a-tooltip>
            </div>
            <a-list item-layout="horizontal" bordered  :data-source="modelList" v-if='modelList.length > 0'>
                <a-list-item slot="renderItem" slot-scope="item, key" class="brand-list" :class="model_id == item.id?'white':''">
                    <div class="item-title"  @click="selectItem('model', item.id)">{{ item.model }}</div>
                    <div class="fade">
                        <a-tooltip placement="bottom" class="icon">
                            <template slot="title">
                                <span>编辑</span>
                            </template>
                            <a-icon @click="doEdit('车型名称', 'modal',item.id,item.model)" class="icon" type="edit" style="margin-right: 10px" />
                        </a-tooltip>
                        <a-tooltip placement="bottom" class="icon">
                            <template slot="title">
                                <span>删除</span>
                            </template>
                            <a-popconfirm title="确认删除该车型么？" @confirm="doDelBrand('model',item.id,key)" okText="确认" cancelText="取消">
                                <a-icon type="delete" />
                            </a-popconfirm>
                        </a-tooltip>
                    </div>
                </a-list-item>
            </a-list>
        </div>
        <div class="car-div">
            <div class="brand-title">
                年份
                <a-tooltip v-if="model_id" placement="bottom">
                    <template slot="title">
                        <span>添加</span>
                    </template>
                    <a-icon @click="addFn('year','添加年份')" class="img" type="plus-circle" />
                </a-tooltip>
            </div>
            <a-list item-layout="horizontal" bordered  :data-source="yearList" v-if='yearList.length > 0'>
                <a-list-item slot="renderItem" slot-scope="item, key" class="brand-list" :class="year_id == item.id?'white':''">
                    <div class="item-title"  @click="selectItem('year', item.id)">{{ item.year }}</div>
                    <div class="fade">
                        <a-tooltip placement="bottom" class="icon">
                            <template slot="title">
                                <span>编辑</span>
                            </template>
                            <a-icon @click="doEdit('年份', 'year',item.id,item.year)" class="icon" type="edit" style="margin-right: 10px" />
                        </a-tooltip>
                        <a-tooltip placement="bottom" class="icon">
                            <template slot="title">
                                <span>删除</span>
                            </template>
                            <a-popconfirm title="确认删除该类型么？" @confirm="doDelBrand('year',item.id,key)" okText="确认" cancelText="取消">
                                <a-icon type="delete" />
                            </a-popconfirm>
                        </a-tooltip>
                    </div>
                </a-list-item>
            </a-list>
        </div>
        <div class="car-div">
            <div class="brand-title">
                配置价格
                <a-tooltip v-if="year_id" placement="bottom">
                    <template slot="title">
                        <span>添加</span>
                    </template>
                    <a-icon @click="addFn('style','添加配置价格')" class="img" type="plus-circle" />
                </a-tooltip>
            </div>
            <a-list item-layout="horizontal" bordered  :data-source="styleList" v-if='styleList.length > 0' >
                <a-list-item slot="renderItem" slot-scope="item, key" class="brand-list" :class="style_id == item.id?'white':''">
                    <div class="item-title"  @click="selectItem('style', item.id)">
                        <div>{{ item.style }}</div>
                        <div style="margin-left:auto;margin-right:15px;">{{item.price}}万</div>
                    </div>
                    <div class="fade">
                        <a-tooltip placement="bottom" class="icon">
                            <template slot="title">
                                <span>编辑</span>
                            </template>
                            <a-icon @click="doEdit('配置价格', 'style',item.id,item.style,item.price)" class="icon" type="edit" style="margin-right: 10px" />
                        </a-tooltip>
                        <a-tooltip placement="bottom" class="icon">
                            <template slot="title">
                                <span>删除</span>
                            </template>
                            <a-popconfirm title="确认删除该类型么？" @confirm="doDelBrand('style',item.id,key)" okText="确认" cancelText="取消">
                                <a-icon type="delete" />
                            </a-popconfirm>
                        </a-tooltip>
                    </div>
                </a-list-item>
            </a-list>
        </div>
        <Create :d="create" v-if="create.show" @visible="visibleFn" @getCarlist='getListFn' />

        <Update :d="edit" v-if="edit.show" @visibleEdit="visibleEditFn"  @getCarEditList='getListEditFn' />
    </div>
</template>

<style lang='scss' scoped>
.page {
    width: 100%;
    height: 100%;
    padding: 20px;
}
.white {
    background: #0e78f0;
    .item-title {
        color: #ffffff;
    }
}
.fade {
    // display: flex;
    // flex-direction: row-reverse;
}
.item-title {
    flex: 1;
    color: #000;
    display: flex;
}
.icon {
    color: #ffffff;
    margin-top: 2px;
}
.brand-list:hover .icon {
    color: #000;
}

.brand-title {
    height: 30px;
    padding: 0 30px;
    font-size: 16px;
    display: flex;
    font-weight: 600;
}

.img {
    margin-left: auto;
    line-height: 30px;
}

.content {
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
}
.brand-list {
    display: flex;
    padding: 10px 5px 10px 10px;
}
.car-div {
    min-width: 240px;
    height: 100%;
    padding: 0 10px;
}
.ant-list-bordered .ant-list-item{
    padding-right: 10px;
}
</style>

<script>
import Create from "./create.vue";
import Update from "./edit.vue";
export default {
    components: { Create, Update },
    data() {
        return {
            branList: [],
            modelList: [],
            yearList: [],
            styleList: [],

            brand_id: 0,
            model_id: 0,
            year_id: 0,
            style_id: 0,

            create: {
                show: false,
                pid: 0,
                key: null,
                title:''
            },
            edit:{
                show: false,
                id: 0,
                key: null,
                title:'',
                val:'',
                val1:'',
                pid:0
            }
        };
    },
    created() {
        this.getList();
    },
    methods: {
        addFn(key,title) {
            this.create.key = key;
            this.create.show = true;
            this.create.title = title
            switch (key) {
                case "model":
                    this.create.pid = this.brand_id;
                    break;
                case "year":
                    this.create.pid = this.model_id;
                    
                    break;
                case "style":
                    this.create.pid = this.year_id;
                    break;
                default:
                    this.create.pid = 0;
            }
            console.log('添加',this.create.pid)
        },
        selectItem(key, id) {
            this[key + "_id"] = id;
             switch (key) {
               case "brand":
                  this.getModelList(id)
                  this.modelList = []
                  this.yearList = []
                  this.styleList = []
                  this.model_id = 0
                  this.year_id = 0
                  this.style_id = 0
                    break;
                case "model":
                  this.getYearList(id);
                  this.yearList = []
                  this.styleList = []
                  this.year_id = 0
                  this.style_id = 0
                    break;
                case "year":
                  this.getStyleList(id); 
                  this.styleList = []
                  this.style_id = 0
                    break;
                default:
            }
           
        },

        doEdit(title,key,id,val,val1){
          this[key + "_id"] = id;
            this.edit = {
                show: true,
                id: id,
                key: key,
                title:title,
                val:val,
                val1:val1
            }
        },
        getListFn(key,id) {
            this[key + "_id"] = id;
             switch (this.create.key) {
               case "brand":
                this.getList()
                this.modelList = []
                this.yearList = []
                this.styleList = []
                this.model_id = 0
                this.year_id = 0
                this.style_id = 0
                    break;
                case "model":
                  this.yearList = []
                  this.styleList = []
                  this.year_id = 0
                  this.style_id = 0
                  this.getModelList(this.brand_id)
                    break;
                case "year":
                  this.styleList = []
                  this.style_id = 0
                  this.getYearList(this.model_id)
                    break;
                case "style":
                  this.getStyleList(this.year_id)
                    break;
                default:
            }
        },
        getListEditFn() {
             switch (this.edit.key) {
               case "brand":
                 this.getList()
                    break;
                case "model":
                  this.getModelList(this.brand_id)
                    break;
                case "year":
                  this.getYearList(this.model_id)
                    break;
                case "style":
                  this.getStyleList(this.year_id)
                    break;
                default:
            }
        },
        visibleFn(){
          this.create.show = false;
        },
        visibleEditFn(){
          this.edit.show = false;
        },
        async getList() {
            const r = await this.$axios.post("/setting/car/getList", {
                pid: 0,
            });
            if (r.status == 1) {
                this.branList = r.list;
            }
        },
        async getModelList(pid) {
            const r = await this.$axios.post("/setting/car/getList", {
                pid: pid,
            });
            if (r.status == 1) {
                this.modelList = r.list;
            }
        },
        async getYearList(pid) {
            const r = await this.$axios.post("/setting/car/getList", {
                pid: pid,
            });
            if (r.status == 1) {
                this.yearList = r.list;
            }
        },
        async getStyleList(pid) {
            const r = await this.$axios.post("/setting/car/getList", {
                pid: pid,
            });
            if (r.status == 1) {
                this.styleList = r.list;
            }
        },

        async doDel(key,id,index) {
            const r = await this.$axios.post("/setting/car/doDel", {
                id: id,
            });
            if (r.status == 1) {
                switch (key) {
                  case "brand":
                      this.branList.splice(index,1)
                      if(this.brand_id == id){
                        this.modelList = []
                        this.yearList = []
                        this.styleList = []
                        this.model_id = 0
                        this.year_id = 0
                        this.style_id = 0
                      }
                        break;
                    case "model":
                      this.modelList.splice(index,1)
                      if(this.model_id == id){
                        this.yearList = []
                        this.styleList = []
                        this.year_id = 0
                        this.style_id = 0
                      }
                        break;
                    case "year":
                      this.yearList.splice(index,1)
                      if(this.year_id == id){
                        this.styleList = []
                        this.style_id = 0
                      }
                        break;
                    case "style":
                      this.yearList.splice(index,1)
                        break;
                    default:
                }
            }
        },
    },
};
</script>